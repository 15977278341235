@font-face {
  font-family: 'San-Francisco';
  src: url('../assets/fonts/SFProDisplay-Bold.woff2') format('woff2'),
    url('../assets/fonts/SFProDisplay-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'San-Francisco';
  src: url('../assets/fonts/SFProDisplay-Medium.woff2') format('woff2'),
    url('../assets/fonts/SFProDisplay-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'San-Francisco';
  src: url('../assets/fonts/SFProDisplay-Thin.woff2') format('woff2'),
    url('../assets/fonts/SFProDisplay-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'San-Francisco';
  src: url('../assets/fonts/SFProDisplay-Regular.woff2') format('woff2'),
    url('../assets/fonts/SFProDisplay-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Pattaya';
  src: url('../assets/fonts/Pattaya-Regular.woff2') format('woff2'),
    url('../assets/fonts/Pattaya-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Genos';
  src: url('../assets/fonts/Genos-Regular.woff2') format('woff2'),
    url('../assets/fonts/Genos-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
