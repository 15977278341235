.start-burger {
  position: absolute;
  right: 25px;
  top: 30px;
  width: 50px;
  height: 38px; //50
  &_none {
    display: none;
  }
  cursor: pointer;
  span,
  span::after,
  span::before {
    position: absolute;
    display: block;
    width: 100%;
    height: 6px; // 10
    background-color: #333;
    border-radius: 5px;
    transition: 0.3s;
  }
  span::before {
    content: '';
    top: -12px; //-20
    transition: top 300ms 300ms, transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  span {
    top: 12px; //20
  }
  span::after {
    content: '';
    bottom: -12px; // -20
    transition: bottom 300ms 300ms,
      transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  // active
  span.active::before {
    content: '';
    top: 0;
    transform: rotate(45deg);
    transition: top 300ms,
      transform 300ms 300ms cubic-bezier(0.68, -0.55, 0, 1.55);
  }
  span.active::after {
    content: '';
    bottom: 0;
    transform: rotate(-45deg);
    transition: top 300ms,
      transform 300ms 300ms cubic-bezier(0.68, -0.55, 0, 1.55);
  }
  span.active {
    background-color: rgba(255, 255, 255, 0);
  }
}
