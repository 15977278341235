html {
  height: 100vh;
  min-height: -webkit-fill-available;
}

body {
  height: 100vh;
  min-height: -webkit-fill-available;
  margin: 0;
  font-family: 'San-Francisco', sans-serif;
  color: #333;
  background-color: #f2f2f2;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
  padding: 0;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

a,
a:active,
a:focus {
  color: #333;
  text-decoration: none;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
}

.container {
  position: relative;
  width: 100%;
  padding: 0 15px;
  overflow: hidden;
}

@media (min-width: 576px) {
  .container {
    width: 475px;
    // width: 540px;
    // padding: 0;
    margin: 0 auto;
  }
}

// @media (min-width: 768px) {
//   .container {
//     width: 720px;
//   }
// }

// @media (min-width: 992px) {
//   .container {
//     width: 960px;
//   }
// }

// @media (min-width: 1200px) {
//   .container {
//     width: 1140px;
//   }
// }

// @media (min-width: 1400px) {
//   .container {
//     width: 1320px;
//   }
// }
