.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90px;
  background-color: #f2f2f2;
  z-index: 10;
  &__logo-box {
    position: absolute;
    left: 20px;
    top: 0;
    width: 160px;
    height: 90px;
    background: url('../../../assets/images/logo/logolws.png') center / contain
      no-repeat;
  }
  &__user-box {
    position: absolute;
    left: 40px;
    top: 25px;
    width: 220px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset -2px -2px 5px #d9d9d9, inset 2px 2px 5px #fff;
    span {
      font-weight: 700;
      font-size: 16px;
    }
  }
  &__name-box {
    position: absolute;
    display: flex;
    align-items: center;
    left: 30px;
    top: 0;
    min-width: 160px;
    height: 90px;
    font-weight: 700;
    font-size: 24px;
  }
}
