.app-burger {
  position: absolute;
  right: 25px;
  top: 28px;
  width: 50px;
  height: 38px;
  cursor: pointer;
  span {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background: url('../../../assets/icons/header/dots-three-outline-light.svg')
      center / cover no-repeat;
    transition: 0.6s transform ease-in-out;
  }
  span.active {
    transform-origin: center;
    transform: rotate(270deg);
  }
}
